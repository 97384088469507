<template>
  <div class="logo">
    <!-- logo is svg -->
    <img src="./assets/logo.png" alt="Vue logo">
  </div>
  <nav>
    <router-link to="/">All accounts</router-link> |
    <router-link to="/add-account">Add Account</router-link> |
    <router-link to="/add-organization">Add Organization</router-link> |
    <router-link to="/about">What's this</router-link>
  </nav>
  <router-view/>
</template>

<style>
.logo{
  display: flex;
  justify-content: center;
  align-items: center;

}

.logo img{
  width: 15%;
  height: 15%;
}


nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: 30px;
}

nav a {
  margin: 0 10px;
  text-decoration: none;  
  color: #000;
} 
</style>
