  import { createRouter, createWebHistory } from 'vue-router'
  import HomeView from '../views/HomeView.vue'

  const routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "about" */ '../views/About.vue')
      }
    },
    {
      path: '/add-account',
      name: 'add-account',
      component: function () {
        return import('../views/AddAccountView.vue')
      }
    },
    {
      path: '/accounts/:organizationId',
      name: 'AccountsView',
      component: function () {
        return import('../views/AccountsView.vue')
      },
      props: true
    },
    {
      path: '/add-organization',
      name: 'add-organization',
      component: function () {
        return import('../views/AddOrganizationView.vue')
      },
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  export default router
