<template>
  <div class="home">
    kloiaFinance ❤️<br>

    <table>
      <thead>
      <tr>
        <th>Organization name</th>
        <th>Actions</th>
        <th>Month</th>
        <th>Year</th>
      </tr>
      </thead>
      <tbody v-if="organizations?.length">
        <tr v-for="organization in organizations" :key="organization.organization_id">
          <td>{{ organization.organization_name  }} Organization </td> 
          <td>
            <router-link :to="{ name: 'AccountsView', params: { organizationId: organization.organization_id, organizationName: organization.organization_name } }">Accounts</router-link>
            <!-- <button @click="deleteOrganization(organization.id)" style="background-color: red;">Delete</button> -->
            <br><button @click="createPDFOrganization(organization.organization_id)">Create PDF</button>
          </td>
          <td>
            <select name="month" id="month" @change="selectedMonth[organization.organization_id] = $event.target.value">
              <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
            </select>
          </td>

          <td>
            <select name="year" id="year" @change="selectedYear[organization.organization_id] = $event.target.value">
              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
          </td>

        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="2">No organizations found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

table {
  border-collapse: collapse;
  width: 50%;
  margin-top: 30px;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {background-color: #f2f2f2;}

button{
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  cursor: pointer;
}

label{
  font-size: 12px;
}
</style>
<script>
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      organizations: [],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      years: [2022, 2023, 2024],
      selectedMonth: {},
      selectedYear: {},
    }
  },
  methods: {
    async getOrganizations() {
      try {
        const response = await axios.get('https://spp-backend.kloia.me/all');
        console.log(response);
        if (response.status === 200) {
          this.organizations = response.data.data;
        }
      } catch (error) {
        console.log(error);
        alert('Something went wrong!');
      }
    },
    async createPDFOrganization(organizationId) {
        try{
          alert('PDF is being created. Please wait...');
          const selectedMonthIndex = this.months.indexOf(this.selectedMonth[organizationId]) + 1;
          const response = await axios.post(`https://spp-backend.kloia.me/organizations/pdf`, {
            organization_id: organizationId,
            month: selectedMonthIndex,
            year: parseInt(this.selectedYear[organizationId]),
          });
          const data = response.data.data;
          if (data.error_account.length > 0 && data.pdf_url) {
            alert('Some accounts are not created: ' + response.data.data.error_account.join(', '))
          }
          window.open(data.pdf_url, '_blank');
        }catch(error){
            alert('Something went wrong!');
        }
    },
  },
  mounted() {
    this.getOrganizations();
  }
}
</script>